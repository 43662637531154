<template>
  <div class="d-flex justify-content-center custom-width">
    <div>
      <div class="main-container p-4 mt-4" ref="body">
        <div
          id="content-policy"
          class="d-flex align-items-center justify-content-between flex-column"
          ref="policyContent"
        >
          <img
            src="../assets/images/SKL/skl-logo.jpg"
            alt="Smiley face"
            width="150"
          />
          <div id="header" class="font-weight-bold" ref="header">
            ข้อกำหนดและเงื่อนไขการใช้บริการ
          </div>
          <div id="policy"></div>
        </div>
      </div>
      <div
        v-if="showBtn"
        class="d-flex flex-column align-items-center policy-bottom-area"
        ref="policyBtnArea"
      >
        <div
          id="checkbox-check"
          class="d-flex flex-row checkbox-area align-items-center"
          @click="clickPolicyCheckBox(0)"
        >
          <div class="checkbox">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 23 23"
            >
              <g
                id="Rectangle_1784"
                data-name="Rectangle 1784"
                fill="#fff"
                stroke="#707070"
                stroke-width="1"
              >
                <rect width="23" height="23" rx="3" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="22"
                  height="22"
                  rx="2.5"
                  fill="none"
                />
              </g>
              <path
                v-if="policyState.seleced"
                id="Path_8841"
                data-name="Path 8841"
                d="M-18087.189-17236.355l5.17,5.17,6.658-11"
                transform="translate(18093 17248)"
                fill="none"
                stroke="#80c141"
                stroke-linecap="round"
                stroke-width="2"
              />
            </svg>
          </div>
          <p class="f-12">รับทราบและยอมรับข้อกำหนดและเงื่อนไขการใช้บริการฯ</p>
        </div>
        <!-- <div
                id="checkbox-check"
                class="d-flex flex-row checkbox-area align-items-center"
                @click="clickPolicyCheckBox(1)"
              >
                <div class="checkbox">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                  >
                    <g
                      id="Rectangle_1784"
                      data-name="Rectangle 1784"
                      fill="#fff"
                      stroke="#707070"
                      stroke-width="1"
                    >
                      <rect width="23" height="23" rx="3" stroke="none" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="22"
                        height="22"
                        rx="2.5"
                        fill="none"
                      />
                    </g>
                    <path
                      v-if="margetTerm"
                      id="Path_8841"
                      data-name="Path 8841"
                      d="M-18087.189-17236.355l5.17,5.17,6.658-11"
                      transform="translate(18093 17248)"
                      fill="none"
                      stroke="#80c141"
                      stroke-linecap="round"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <a
                  class="f-12 text-link"
                  href="https://www.google.co.th/?hl=th"
                  target="_blank"
                >
                  รับบทราบและยอมรับข้อกำหนดและเงื่อนไขการใช้บริการฯ
                </a>
              </div> -->
        <b-button
          class="mt-2 w-50 submit-policy"
          id="submit-policy"
          :disabled="!policyState.seleced || buttonLoading"
          variant="submit"
          @click="submitPolicy"
        >
          <b-spinner small v-if="buttonLoading"></b-spinner>
          <span v-else>ยินยอม</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  beforeCreate() {
    let liffId = "";
    // if (
    //   window.location.hostname !== "dev-line-eservice.skl.co.th" ||
    //   window.location.hostname !== "line-eservice.skl.co.th"
    // ) {
    //   liffId = "1654133345-kyzZWPD9";
    // } else
    liffId = this.$liff_ID_Policy;
    this.$liff
      .init({ liffId: liffId })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.checkTerm(profile.userId);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  data: () => ({
    policyState: {
      showCheckBox: false,
      seleced: false
    },
    lineProfile: {},
    isHaveContract: false,
    isNotTerm: false,
    showBtn: false
  }),

  mounted() {
    document.body.classList.add("bg-body");
    this.checkConsentRegister();
  },
  methods: {
    checkConsentRegister() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/Customer/TermsAndConditions`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            document.getElementById("policy").innerHTML = result.detail.content;
          }
        })
        .catch(error => console.log("error", error));
    },
    clickPolicyCheckBox(type) {
      if (type == 0) {
        this.policyState.seleced = !this.policyState.seleced;
      } else this.margetTerm = !this.margetTerm;
    },
    CheckContract() {
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/CheckContract/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.isHaveContract = result.detail.isHaveContract;
            if (this.isHaveContract && this.isNotTerm) {
              this.showBtn = true;
            }
          } else {
            if (this.isNotTerm) {
              this.showBtn = true;
            }
          }
        })
        .catch(error => console.log("error", error));
    },
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              this.isNotTerm = true;

              this.CheckContract();
            }
          } else {
            this.isNotTerm = false;
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    submitPolicy() {
      if (this.policyState.seleced) {
        var requestOptions = {
          method: "GET",
          redirect: "follow"
        };
        fetch(
          `${this.$API_DEV_LINE_SKL}/Customer/terms/${this.lineProfile.userId}`,
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            if (result.result == 1) {
              this.$router.push("/installment/0");
            }
          })
          .catch(error => console.log("error", error));
      }
    }
  }
};
</script>

<style scoped>
.bg-body {
  background-color: white !important;
}

/* .sub-container {
  height: calc(100vh - 100px);
} */

.main-container {
  /* height: calc(100vh - 100px); */
  background-color: white !important;
  width: calc(100vw + 10px);
  border: 5px #7fc241 solid;
  border-bottom: none;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

::v-deep .custom-select {
  padding: 0.375rem 0.75rem 0.375rem 0.75rem !important;
}

.policy-bottom-area {
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100vw;
  background-color: white;
}

.checkbox {
  margin-left: 12px;
  margin-right: 12px;
}

.disable {
  filter: grayscale(100%);
}
.checkbox-area {
  margin-top: 5px;
  margin-bottom: 5px;
}
/* .w-100.submit-policy {
  width: 100% !important;
} */
</style>
